import ReactOnRails from 'react-on-rails';
import OverviewRoutes from 'bundles/overview/OverviewRoutes';
import NavOverview from 'bundles/overview/components/NavOverview';
import BottomNavOverview from 'bundles/overview/components/BottomBar';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  OverviewRoutes,
  NavOverview,
  BottomNavOverview
});
