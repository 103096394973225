/* ============================================================================
* 
* Authors: Joshua Chang
*
* Created On: November 11th, 2022
* Modified On:
============================================================================ */

import React, { Component } from "react";

import { Card, Col, Container, Row, Image, Button } from "react-bootstrap";

import FeedLayout from "../../shared/components/layouts/FeedLayout";
import { AiFillCaretDown } from 'react-icons/ai';
import { BsFillPeopleFill } from 'react-icons/bs';

import VideoFrame from "../../shared/components/VideoFrame";

class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }


    render() {
        return (
            <FeedLayout>
                <div className="landing">
                    <style>
                        @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');
                    </style>

                    <div className='video-background pure-u-3-24'>
                        <h1 className='title pure-u-3-24'>SWARM Simulation Platform</h1>
                        <h3 className='subtitle pure-u-3-24'>SWARM helps build the future of AI-enabled autonomous robotics through cloud-based, easily scalable, photorealistic simulation</h3>
                        <div className="gradient-overlay">

                            <VideoFrame embedId={"t2V6jgKT7mo?version=3&autoplay=1&loop=1&mute=1&controls=0&playlist=t2V6jgKT7mo&modestbranding=1"} fluid={true} title={"Competition Vidoe - SWARM Simulation Platform"} />

                            <AiFillCaretDown className='downicon' />
                        </div>
                        <button className='startbutton' color="#f194ff" onClick={() => window.open("https://www.swarmsim.io/overview/demo_request")}>Request A Demo</button>
                    </div>
                    <Card body bg='light' className="non-pure-u-3-24">
                        <h1 className="text-center small-page-title">SWARM Simulation Platform</h1>
                        <h3 className="subtitle pure-u-3-24">SWARM helps build the future of AI-enabled autonomous robotics through cloud-based, easily scalable, photorealistic simulation</h3>
                    </Card>

                    <div className='descriptions'>
                        <h1 className='descriptiontitle'>What is SWARM?</h1>
                        <p className='description'>SWARM is a cloud-based simulation platform that provides a simple easy-to-use user-interface for building autonomous flight control algorithms used by developers from all levels of background. It is designed to be a flexible and extensible platform for researchers to develop and test new swarm flight control algorithms using various simulated environments. It is built on top of the open-source flight simulator AirSim, and is designed to be compatible with the PX4 flight stack.</p>
                        <Row>
                            <Col lg='6'>
                                <Card className='functionality'>
                                    <h2>Simulation Engine</h2>
                                    <p>The SWARM simulation platform features a novel cloud-based architecture that automates the entire test process by allowing developers to live stream the advanced simulation graphics in various testing environments with no installation.  </p>
                                    <button type="button" className='pure-u-3-24 detailsbutton' onClick={() => window.open("https://www.swarmsim.io/overview/information")}>Details</button>
                                    <button type="button" className='non-pure-u-3-24 non-detailsbutton' onClick={() => window.open("https://www.swarmsim.io/overview/information")}>Details</button>
                                    <iframe className="detailmedia" src="https://www.youtube.com/embed/VLWUpgyu3vg?mute=1" title="competitionvid" allowFullScreen autoPlay muted></iframe>
                                </Card>
                            </Col>

                            <Col lg='6'>
                                <Card className='functionality'>
                                    <h2>Data analysis</h2>
                                    <p>The SWARM simulation platform provides automated performance analytics including comprehendsive data sets and helpful visualizations allowing users to streamline the data analysis process.</p>
                                    <button type="button" className='pure-u-3-24 detailsbutton' onClick={() => window.open("https://www.swarmsim.io/overview/information")}>Details</button>
                                    <button type="button" className='non-pure-u-3-24 non-detailsbutton' onClick={() => window.open("https://www.swarmsim.io/overview/information")}>Details</button>
                                    <Image fluid className="detailmedia" src="https://zero1maintphotos.s3.us-east-2.amazonaws.com/RendezvousCommunicationGraph.png" alt="dataGraph" />
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg='6'>
                                <Card className='functionality'>
                                    <h2>Algorithm builder</h2>
                                    <p>Users can design custom algorithms using an easy-to-use graphical user-interface with minimal code. With various open-source API's and third-party libraries, flight control algorithms can be built according to the user's needs. Built-in obstacle avoidance and path planning algorithms are provided to easily get started in the development process.</p>
                                    <button type="button" className='pure-u-3-24 detailsbutton' onClick={() => window.open("https://www.swarmsim.io/dashboard/knowledge/index")}>Details</button>
                                    <button type="button" className='non-pure-u-3-24 non-detailsbutton' onClick={() => window.open("https://www.swarmsim.io/dashboard/knowledge/index")}>Details</button>
                                    <Image fluid className='detailmedia' src="https://zero1maintphotos.s3.us-east-2.amazonaws.com/UserInterface.PNG" alt="UserInterface" />
                                </Card>
                            </Col>

                            <Col lg='6'>
                                <Card className='functionality'>
                                    <h2>Community Space</h2>
                                    <p>Developers can use the community forum to share your simulation results and progress, or ask questions. Sign up now and join the community to share the latest research development in multi-agent and singular UAV algorithms.</p>
                                    <button type="button" className='pure-u-3-24 detailsbutton' href="/dashboard/competition/index">Coming Soon</button>
                                    <button type="button" className='non-pure-u-3-24 non-detailsbutton' onClick={() => window.open("https://www.swarmsim.io/dashboard/knowledge/index")}>Details</button>
                                    <BsFillPeopleFill className='detailmedia' />
                                </Card>
                            </Col>
                        </Row>
                    </div>


                    <div className='video-background'>

                        <h1 className='title'>SWARM simulation Competition</h1>
                        <h3 className='subtitle pure-u-3-24'>Develop your own drone flight control algorithms and compete against others</h3>
                        <h3 className='subtitle2 pure-u-3-24'>Sign up now to participate in the competition</h3>
                        <div className="gradient-overlay">

                            <iframe className='competeVideo' src="https://www.youtube.com/embed/BojQ_xW_72k?version=3&autoplay=1&loop=1&mute=1&controls=0&playlist=BojQ_xW_72k," title="competitionvid" allowFullScreen autoPlay muted></iframe>
                        </div>
                        <button className='competebutton' color="#f194ff" onClick={() => window.open("https://www.swarmsim.io/dashboard/competition/index")}>View Competition</button>
                    </div>
                    <div className='links'>
                        <Row>
                            <Col md='4'>
                                <h2 className='descriptiontitle'>Documentation</h2>
                                <p>Learn more about the open-source API Colosseum and detailed tutorials</p>
                                {/* button link to youtube */}
                                <button type="button" className='detailsbutton' onClick={() => window.open("https://codexlabsllc.github.io/web-portal/index.html")}>Docs</button>
                            </Col>
                            <Col md='4'>
                                <h2 className='descriptiontitle'>About us</h2>
                                <p>Meet the team behind SWARM Research</p>
                                <button type="button" className='detailsbutton' onClick={() => window.open("https://www.swarmsim.io/overview/about_us")}>About</button>
                            </Col>
                            <Col md='4'>
                                <h2 className='descriptiontitle'>SWARM Developer Version</h2>
                                Looking for something more? We took our web framework and have created a cloud-optional, Python-first simulation framework.
                                <br />
                                <Button className='detailsbutton' variant='dark' href="/overview/developer" color="#f194ff" >More Info</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </FeedLayout>
        );
    }
}

export default LandingPage;
