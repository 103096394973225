/*
* Copyright 2022. Codex Laboratories LLC.
* Created By: Tyler Fedrizzi
* Modified By:
*
* Created On: October 18th, 2022
* Modified On: 
*
* Description: Framework for all Data Views
*/
import React, { Component } from 'react';

import { Row, Col } from 'react-bootstrap';


class DataViewLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    };

    render() {
        return (
        <> 
            <Row>
                <Col md='1' xl='2' />
                <Col md='10' xl='8'>
                    {this.props.children}
                </Col>
                <Col md='1' xl='2' />
            </Row>
        </>
        )
    }
}

export default DataViewLayout;