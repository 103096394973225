/*
* Copyright Codex Laboratories LLC 2021.
* Created By: Tyler Fedrizzi
* Modified By:
* Created On: November 23, 2021
* Modified On: 
*
* Description: Banner to display content to the user.
*/
import React, { Component } from 'react';

import { Alert } from 'react-bootstrap';



class ToastMessage extends Component {
    timeID = null;
    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    };

    componentDidMount = () => {

        if (this.props.useTimeout) {
            var timeout = 3000  // Milliseconds
            if (this.props.timeout) {
                timeout = this.props.timeout;
            }
            this.timeId = setTimeout(() => {
                // After 3 seconds set the show value to false
                this.props.hideToast();
              }, timeout);
          
              return () => {
               
              };
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.timeId);
    }
    

    render() {
        return (
            <>
                <Alert transition={true}  variant={this.props.variant} onClose={this.props.dismissToast} dismissible>
                    {this.props.hasHeader ? <Alert.Heading>{this.props.heading}</Alert.Heading> : null }
                    {this.props.children}
                </Alert>
            </>
        )
    }
}

export default ToastMessage;