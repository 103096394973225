import React from "react";
import PropTypes from "prop-types";

// <VideoFrame embedId={"t2V6jgKT7mo"} fluid={true} title={"Competition Vidoe - SWARM Simulation Platform"} />

const VideoFrame = ({ embedId, width, height, title }) => (
  <div className="video-responsive">
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      autoPlay={true}
      title={title}
    />
  </div>
);

VideoFrame.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default VideoFrame;