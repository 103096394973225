/*
* Copyright Codex Laboratories LLC 2022.
* Created By: Tyler Fedrizzi
* Modified By:
* Created On: January 8, 2022
* Modified On: 
*
* Description: Navigation bar for viewing Overview of SWARM system
*/
// React Imports
import React, { Component } from 'react';

// Bootstrap Imports
import { Navbar, Nav, Form, Button, Card, Row, Col, FormControl } from 'react-bootstrap';

import { MailingListForm } from '../../shared/components/Forms/MailingListForm';

import validator from 'validator';


class BottomNavOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailError: null,
            successMessage: null,
            showSuccess: false
        }
    };

    handleFormSubmission = async (e) => {
        let name = e.target.elements.name.value;
        let email = e.target.elements.email.value;
        console.log(name, email);

        if (name === null || email === null) {
            console.log("Form was empty!");
            return;
        }
        const isValid = this.validateSubmission(name, email);
        console.log(isValid);
        if (isValid) {
            const formData = new FormData();

            formData.append('mailing_list[name]', name);
            formData.append('mailing_list[email]', email);

            const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

            // Post basic information to the simulations db
            const success = await fetch('/api/v1/mailing_lists/add_user', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': csrf
                },
                body: formData
            }).then((res) => {
                if (res.status == 200) {
                    res.json();
                    return true;
                } else if (res.status == 400) {
                    alert(res.json().error);
                    return false;
                } else if (res.status == 500) {
                    alert("Internal Error. Please try again!");
                    return false;
                } else {
                    alert("Unkown error. Please submit a help ticket!");
                    return false;
                }
            });
            if (success) {
                console.log("Success!")
                this.setState({
                    successMessage: `Thank you ${name}. Submission Successful!`,
                    showSuccess: true
                })
                document.getElementById('name').value = null;
                document.getElementById('email').value = null;
            }
        }
    } // handleFormSubmission

    validateSubmission = (name, email) => {
        let isValid = true;
        if (name === "") {
            alert("Name cannot be blank!");
            return false;
        } else if (email === "") {
            alert("Email cannot be blank!");
            return false;
        }

        var email_statement = null;
        if (validator.isEmail(email)) {
            email_statement = 'Valid Email :)';
        } else {
            console.log("Invalid email detected!")
            email_statement = 'Enter valid Email!';
            this.setState({
                emailError: email_statement
            });
            return false;
        }
        this.setState({
            emailError: null
        });
        return isValid;
    } // validateSubmission

    closeSuccessMessage = () => {
        this.setState({
            showSuccess: false
        });
    } // closeSuccessMessage

    render() {
        return (
            <Navbar bg="primary" variant="primary" expand="lg" fixed="bottom" role="navigation">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav d-flex align-content-center">
                    <span></span>
                    <Nav className='mx-2'>
                        <Form onSubmit={(e) => { e.preventDefault(); this.handleFormSubmission(e); }} name="mailing_list_form" id="mailing_list_form" scope="mailing_list">

                            <Row className="align-items-center mt-2">
                                <Col sm={"auto"}>
                                    <h5 style={{ color: "white" }}>Join the Mailing List:</h5>
                                </Col>
                                <Col sm={"auto"}>
                                    <Form.Control
                                        name="mailing_list[name]"
                                        type="text"
                                        placeholder="Name"
                                        id="name"
                                    ></Form.Control>
                                </Col>
                                <Col sm="auto">
                                    <FormControl type="text" id="email" name="mailing_list[email]" placeholder="Email"></FormControl>
                                    <span style={{
                                        fontWeight: 'bold',
                                        color: 'red',
                                    }}>{this.state.emailError}</span>
                                </Col>

                                <Col sm="auto">
                                    <Button type="submit" variant="success" block>
                                        Submit
                                    </Button>
                                </Col>
                                <Col sm="auto">
                                    {console.log(this.state.showSuccess)}
                                    {this.state.showSuccess ?
                                        <Card.Body className="rounded p-1 m-1" style={{ color: "white" }}>
                                            <Row>
                                                <Col md="8">
                                                    <h6>{this.state.successMessage}</h6>
                                                </Col>
                                                <Col md="4">
                                                    <Button block onClick={() => { this.closeSuccessMessage() }} size="sm" variant='danger'>
                                                        <i className="far fa-times-circle me-2"></i>Close
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                        : null}
                                </Col>
                            </Row>
                        </Form>
                    </Nav>
                    <span></span>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default BottomNavOverview;