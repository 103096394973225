/*
* Copyright Codex Laboratories LLC 2021.
* Created By: Tyler Fedrizzi
* Modified By:
* Created On: November 23, 2021
* Modified On: 
*
* Description: Core Data Log component to display the various log files
*              generated during the execution of a simulation.
*/
import React, { Component } from 'react';
import scrollToElement from 'scroll-to-element';

// Bootstrap
import { Row, Col, Button, Card, Container, Image } from 'react-bootstrap';

// Video Player
import VideoFrame from '../../shared/components/VideoFrame';

class AboutUs extends Component {

    constructor(props) {
        super(props);
        let section_header = "overview";
        try {
            section_header = props.location.state.section_header;
        } catch (error) {
            console.log("There was no section header!");
        }
        this.state = {
            section_header: section_header
        }
    };

    componentDidMount() {
        this.jumpToHash();
    }
    /* componentDidUpdate() {
        this.jumpToHash();
    }*/
    jumpToHash = () => {
        console.log(this.state.section_header);
        if (this.state.section_header) {
            console.log(this.state.section_header);
            scrollToElement(`#${this.state.section_header}`, { offset: -40 });
        }
    }

    render() {
        return (
            <>
                <InfoSection id="mission_statement" title="Our Mission Statement" rowStyle={{ paddingTop: '65px' }}>
                    <Card body variant='secondary'>
                        Build an inclusive, high-performance platform that enables users of all levels,
                        from novice to advance, to have access to robotic systems. Through this platform,
                        we will enable the advancement of real-world robotics applications by developing
                        innovative methods to stress test algorithms and reduce the amount of time to go
                        from idea to implemenation.
                    </Card>
                </InfoSection>

                <InfoSection id="about_us" title="Our Team">
                    <p>
                        The SWARM Simulation Platform is the product of work of many different
                        members and organizations. Primarily, this project has been the collaboration
                        between Codex Laboratories LLC and Purdue University's Elmore School of Electrical
                        and Computer Engineering. Scroll down to <b>How We Started</b> read more about how
                        this team came together.
                    </p>
                    <Container fluid>
                        <Row>
                            <Col md="4">
                                <PeopleCard
                                    imageLink={"https://zero1maintphotos.s3.us-east-2.amazonaws.com/ID+Card+Photo.jpg"}
                                    name={"Tyler Fedrizzi"}
                                    bio={"Tyler is the co-founder of Codex Laboratories LLC and a U.S. Army veteran. A graduate of Purdue University, Tyler has been working on robotic simulation for over 5 years and has practical experience building and deploying robotic systems at Johns Hopkins University, Raytheon Technologies, N.A.S.A. and Booz Allen Hamilton."}
                                    linked_in_url={"https://www.linkedin.com/in/tyler-fedrizzi-46170685/"}
                                />
                                </Col>
                                <Col md="4">
                                <PeopleCard
                                    imageLink={""}
                                    name={"Professor Shreyas Sundaram"}
                                    bio={"Professor Sundaram is an Associate Professor of Electrical Engineering at the Elmore School of Electrical and Computer Engineering at Purdue University. Professor Sundaram has expertise in optimal control theory, networks, and multi-agent systems."}
                                    linked_in_url={"https://www.linkedin.com/in/shreyas-sundaram-676a926/"}
                                />
                                </Col>
                                <Col md="4">
                                <PeopleCard
                                    imageLink={""}
                                    name={"Josh Chang"}
                                    bio={"Bio coming soon!"}
                                    linked_in_url={"https://www.linkedin.com/in/joshua-chang-40/"}
                                />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                        <Col md="4">
                                <PeopleCard
                                    imageLink={""}
                                    name={"Avi Dube"}
                                    bio={"Avi is a junior at Purdue University, pursuing a degree in Computer Engineering with a specialization in the integration of AI into autonomous systems. As the dedicated team lead of the research group, Avi directs their efforts toward developing cutting-edge controls and obstacle avoidance algorithms. With a solid foundation in both academics and practical experience, Avi has cultivated a profound understanding of robotics through hands-on involvement in robotics teams and a valuable internship with SAAB's AI/ML team. Eager to continue pushing the boundaries of AI in autonomy, Avi is poised to make significant contributions in this dynamic field."}
                                    linked_in_url={"https://www.linkedin.com/in/amikoshdube/"}
                                />
                                </Col>
                                <Col md="4">
                                
                                </Col>
                                <Col md="4">
                            </Col>
                        </Row>
                    </Container>
                </InfoSection>
                <InfoSection id="history" title="How We Started" rowStyle={{paddingBottom: '65px'}}>
                    <p>
                        The SWARM Simulation Platform started as independent research at Purdue University. Tyler Fedrizzi
                        was helping Professor Sundaram research different simulation platforms that existed. As the research
                        continued, both Professor Sundaram and Tyler began to realize a few negative aspects of widely available
                        simulators. In general, what was found that while many simulators have a number of tools and capabilities,
                        most require significant amounts of work to build and design. For any person who wishes to simulate multiple
                        agents, this amount of work would only multiply. Furthermore, when attempting to test state of the art algorithms
                        there was little if any known benchmarks or implementations that were readily available and easily integrated.
                    </p>
                    <p>
                        As more time went on, Tyler and Professor Sundaram formulated a plan to build a simulator that would
                        address some of these core challenges. Starting in Purdue University's Vertically Integrated Project (VIP),
                        the team quickly built a functioning prototype website and cloud system that was capable of simulating mulitple
                        agents.
                    </p>
                    <p>
                        Nearly 2 years later, this platform has evolved to serve many different purposes and to provide a common
                        platform for everyone to utilize. Updates continue are deployed weekly or as soon as available.
                    </p>
                </InfoSection>
            </>
        )
    }
}

class InfoSection extends Component {

    render() {
        return (
            <>
                <Row id={this.props.id} className='mx-0' style={this.props.rowStyle}>
                    <Col sm="2"></Col>
                    <Col sm="8">
                        <Card className="shadow mb-4">
                            <Card.Body>
                                <Card.Title as="h2" className="text-center swarm-banner">
                                    {this.props.title}
                                </Card.Title>
                                {this.props.children}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm="2"></Col>
                </Row>
            </>
        )
    }
}

class PeopleCard extends Component {
    render() {
        return (
            <Card className="shadow-sm">
                <Card.Img variant="top" src={this.props.imageLink} />
                <Card.Body>
                    <h3 className='text-center'>{this.props.name}</h3>
                    {this.props.bio}
                    <br/>
                    {
                        this.props.linked_in_url.length > 0 ?
                            <a href={this.props.linked_in_url}><i class="fa-brands fa-linkedin fa-xl"></i></a> :
                            <></>
                    }
                </Card.Body>
            </Card>
        )
    }
}

export default AboutUs;