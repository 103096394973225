/*
* Copyright Codex Laboratories LLC 2021.
* Created By: Tyler Fedrizzi
* Modified By:
* Created On: January 12, 2022
* Modified On: 
*
* Description: Core Data Log component to display the various log files
*              generated during the execution of a simulation.
*/
import React, { Component } from 'react';

import { Row } from 'react-bootstrap';


class FeedLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    };

    render() {
        return (
        <> 
            <div style={{ paddingTop: '69px', paddingBottom: '20px' }}>
                {this.props.children}
            </div>
        </>
        )
    }
}

export default FeedLayout;