/*
* Copyright Codex Laboratories LLC 2021.
* Created By: Tyler Fedrizzi
* Modified By:
* Created On: January 22, 2023
* Modified On: 
*
* Description: Developer System overview.
*/
import React, { Component } from "react";

import { Card, Col, Container, Row, Button, Image, Ratio } from "react-bootstrap";

import FeedLayout from "../../shared/components/layouts/FeedLayout";
import { AiFillCaretDown } from 'react-icons/ai';
import { BsFillPeopleFill } from 'react-icons/bs';

import VideoFrame from "../../shared/components/VideoFrame";

class Developer extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    };

    componentDidMount() {

    }

    render() {
        return (
            <>
                <FeedLayout>
                    <div className="landing">
                        <style>
                            @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');
                        </style>
                        <div className='video-background pure-u-3-24'>
                            <div className="gradient-overlay">
                                <VideoFrame embedId={"y1LFW6e7aBU?version=3&autoplay=1&loop=1&mute=1&controls=0&playlist=y1LFW6e7aBU&modestbranding=1"} fluid={true} title={"Competition Vidoe - SWARM Simulation Platform"} />

                                <AiFillCaretDown className='downicon' />
                            </div>
                        </div>
                        <Card body bg='light' className="non-pure-u-3-24">
                            <h1 className="text-center small-page-title">SWARM Robotics Development System (RDS)</h1>
                            <h3 className="subtitle pure-u-3-24">Our most advanced simulator yet, all through the convience of a Python API or easy-to-use GUI. Cloud-optional, collaborative robot building framework.</h3>
                        </Card>
                        <div className='descriptions'>
                            <h1 className='descriptiontitle'>SWARM Robotics Development System</h1>
                            <p className='description'>We have designed and built a simulation system from the ground up to run natively on your local computer, local cloud, hybrid cloud or really any cloud you can think of. With the SWARM Developer System, you can quickly and easily run multiple simulations, develop algorithms and access photorealistic, dynamic environments with ease!</p>
                            <Row>
                                <Col md='4'></Col>
                                <Col md='4'>
                                    <div className='d-grid'>
                                        <Button size='lg' variant='outline-primary' href="/overview/demo_request" color="#f194ff" >Request Demo</Button>
                                    </div>
                                </Col>
                                <Col md='4'></Col>
                            </Row>
                            <Row>
                                <Col lg='6'>
                                    <Card className='functionality border-primary'>
                                        <h2>Simple, Easy-To-Use Framework</h2>
                                        <p>We started from the basics, building a platform built for machine learning, autonomous system development and system validation. Simple, single-application download and install with Cloud-native integration.</p>
                                        <Button variant='outline-success' className='mb-2 pure-u-3-24 ' onClick={() => window.open("https://codexlabsllc.github.io/SWARM-RDS-Client-Dev/index.html")}>Details</Button>
                                        <Button variant='outline-success' className='mb-2 non-pure-u-3-24 ' onClick={() => window.open("https://codexlabsllc.github.io/SWARM-RDS-Client-Dev/index.html")}>Details</Button>
                                        <Ratio aspectRatio="4x4">
                                        <iframe className="detailmedia" src="https://www.youtube.com/embed/x9Js8kb_fE4?mute=1" title="SWARM RDS Client Demo" allowFullScreen autoPlay muted></iframe>
                                        </Ratio>
                                    </Card>
                                </Col>
                                <Col lg='6'>
                                    <Card className='functionality border-primary'>
                                        <h2>Advanced Graphics</h2>
                                        <p>We did the hard work for you. All environments are built with the latest Unreal Engine, with Zero installation, setup or headaches.</p>
                                        <Button variant='outline-success' className='pure-u-3-24 ' onClick={() => window.open("https://www.swarmsim.io/overview/information")}>Details</Button>
                                        <Button variant='outline-success' className='non-pure-u-3-24 ' onClick={() => window.open("https://www.swarmsim.io/overview/information")}>Details</Button>
                                        <iframe className="detailmedia" src="https://www.youtube.com/embed/VLWUpgyu3vg?mute=1" title="competitionvid" allowFullScreen autoPlay muted></iframe>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='6'>
                                    <Card className='functionality border-primary'>
                                        <h2>Cloud Optional</h2>
                                        <p>With Docker, we enabled the ability to run this simulation from anywhere, with a simple, local or private network, web-based viewer. This means no more figuring out displays, X11 servers, Vulkan drivers and all of the other fun involved with high-fidelity systems.</p>
                                        
                                        <Image className='p-3' rounded fluid src='https://zero1maintphotos.s3.us-east-2.amazonaws.com/SWARM_Web_Viewer.png' />
                                    </Card>
                                </Col>
                                <Col lg='6'>
                                    <Card className='functionality border-primary'>
                                        <h2>Multi-Agent Enabled</h2>
                                        <p>Simulating multiple agents is very challenging. We provide native support, with the ability to customize each agent's physics, sensors and software, all running seamlessly in the cloud or on your desktop!</p>
                                        <iframe className="detailmedia" src="https://www.youtube.com/embed/4hGn3YKWdow?mute=1" title="SWARM RDS Client Demo" allowFullScreen autoPlay muted></iframe>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='6'>
                                    <Card className='functionality border-primary'>
                                        <h2>Python-first API</h2>
                                        <p>To provide flexibility and ease development, we designed a Python-first framework, allowing you to easily and quickly get started. From there, we have C++ implementation bindings to integrate any platform of your choice.</p>
                                        <Button variant='outline-success' className='mb-2 pure-u-3-24 ' onClick={() => window.open("https://codexlabsllc.github.io/SWARM-RDS-Client-Dev/index.html")}>Details</Button>
                                        <Button variant='outline-success' className='mb-2 non-pure-u-3-24' onClick={() => window.open("https://codexlabsllc.github.io/SWARM-RDS-Client-Dev/index.html")}>Details</Button>
                                    </Card>
                                </Col>
                                <Col lg='6'>
                                    <Card className='functionality border-primary'>
                                        <h2>SWARM Colosseum</h2>
                                        <p>Leveraging the power of open-source, we continue to work with the community and develop a platform for everyone to build autonomous systems!</p>
                                        <Button variant='outline-success' className='mb-2 pure-u-3-24 ' onClick={() => window.open("https://github.com/CodexLabsLLC/Colosseum")}>Details</Button>
                                        <Button variant='outline-success' className='mb-2 non-pure-u-3-24' onClick={() => window.open("https://github.com/CodexLabsLLC/Colosseum")}>Details</Button>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <div className='links'>
                            <Container>
                                <h2 className='descriptiontitle'>Python Documentation</h2>
                                <p>Learn more about the API and detailed tutorials</p>
                                {/* button link to youtube */}
                                <Button variant='outline-success' className='detailsbutton' onClick={() => window.open("https://codexlabsllc.github.io/SWARM/index.html")}>Docs</Button>
                            </Container>
                            <Container>
                                <h2 className='descriptiontitle'>Request A Demo</h2>
                                <p>Contact us today to get access to our web-only demo! No downloads, no complicated setup, just pure simulation at your fingertips!</p>
                                {/* button link to youtube */}
                                <Button variant='dark' className='detailsbutton' href="/overview/demo_request" >Request Demo</Button>
                            </Container>
                        </div>
                    </div>
                </FeedLayout>
            </>
        )
    }
}

export default Developer;