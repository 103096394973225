/*
* Copyright Codex Laboratories LLC 2021.
* Created By: Tyler Fedrizzi
* Modified By:
* Created On: January 22, 2023
* Modified On: 
*
* Description: Request more information on.
*/
import React, { Component } from "react";

import { Card, Col, Form, Row, Spinner, Button } from "react-bootstrap";

import FeedLayout from "../../shared/components/layouts/FeedLayout";
import DataViewLayout from "../../shared/components/layouts/DataViewLayout"
import ToastMessage from "../../shared/components/layouts/ToastMessage";

class DemoRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            showToast: false,
            toastMessage: "",
            toastVariant: "success"
        }
    };

    componentDidMount() {

    }

    /**
     * @brief Handle the submission fo a form
     * 
     * @param {Event} event - Event of a user clicking on the form
     */
    handleSubmit = async (event) => {
        var form = event.currentTarget;

        console.log(event.currentTarget.elements);
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({
                validated: true
            });
            return;
        }

        this.setState({
            loading: true
        });

        var success = await this.submitDeveloperInterestForm(event);
        
        console.log(success);
        if (!success) {
            this.setState({
                loading: false,
                validated: true
            });

            return;
        }
        this.setState({
            validated: true,
            showToast: true,
            loading: false,
            toastMessage: "Submission successful! We will contact you shortly!"
        });

        return;
    } // handleSubmit

    /**
     * @brief Submit the form data to 
     * 
     * @param {Event} event - Data from the form to submit
     */
    submitDeveloperInterestForm = async (event) => {
        let first_name = event.currentTarget.elements.first_name.value;
        let last_name = event.currentTarget.elements.last_name.value;
        let email = event.currentTarget.elements.email.value;
        let addToMailingList = event.currentTarget.elements.mailing_list.checked;
        console.log(first_name, last_name, email, addToMailingList);

        const demoRequestFormData = new FormData();

        demoRequestFormData.append('demo_request[first_name]', first_name);
        demoRequestFormData.append('demo_request[last_name]', last_name);
        demoRequestFormData.append('demo_request[email]', email);

        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

        // Post basic information to the simulations db
        const simulation = await fetch('/api/v1/demo_requests/add_user', {
            method: 'POST',
            headers: {
                'X-CSRF-Token': csrf
            },
            body: demoRequestFormData
        }).then(res => {
            if (res.status == 200) {
                return res.json();
            } else if (res.status == 400) {
                alert(res.json())
                return false;
            } else if (res.status == 422) {
                alert("You have already submitted a request with this email!")
                return false;
            } else if (res.status == 500) {
                alert("Internal Error. Please try again!");
                return false;
            } else {
                alert("Unkown error. Please submit a help ticket!");
                return false;
            }
        });

        if (!simulation) {

            return false;
        }


        if (addToMailingList) {
            const formData = new FormData();

            formData.append('mailing_list[name]', first_name + " " + last_name);
            formData.append('mailing_list[email]', email);

            const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

            // Post basic information to the simulations db
            const simulation = await fetch('/api/v1/mailing_lists/add_user', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': csrf
                },
                body: formData
            }).then(res => {
                if (res.status == 200) {
                    return res.json();
                } else if (res.status == 400) {
                    alert(res.json())
                    return res.json();
                } else if (res.status == 500) {
                    alert("Internal Error. Please try again!");
                    return res.json();
                } else {
                    alert("Unkown error. Please submit a help ticket!");
                    return res.json();
                }
            });

            // alert("You were added to our list! Thank you!");
            
        }
        return true;
    }

    /**
     * @brief Dismiss the toast message if the user clicks on x
     */
    dismissToast = () => {
        this.setState({
            showToast: false
        });
    } // dismissToast

    render() {
        return (
            <>
                <FeedLayout>
                    <DataViewLayout>
                        <Card className='shadow'>
                            <Card.Body>
                                <h2 className='text-center'>Request a Demonstration</h2>
                                <Row className="mb-3">
                                    <Col md='4'></Col>
                                    <Col md='4'>
                                        <p>
                                            Please fill out the form below and someone from our team will contact you
                                            with instructions to send more information.
                                        </p>
                                    </Col>
                                    <Col md='4'></Col>
                                </Row>

                                <Form controlId="demoRequestForm" noValidate validated={this.state.validated} onSubmit={(e) => { e.preventDefault(); this.handleSubmit(e); }}>
                                    <Row className="mb-3">
                                        <Col md='4'></Col>
                                        <Form.Group as={Col} md="4" controlId="first_name">
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="First name"
                                            />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Col md='4'></Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md='4'></Col>
                                        <Form.Group as={Col} md="4" controlId="last_name">
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Last name"
                                            />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Col md='4'></Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md='4'></Col>
                                        <Form.Group as={Col} md="4" controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Input your email"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a valid email!
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Col md='4'></Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md='4'></Col>
                                        <Col md='4'>
                                            <Form.Group controlId="mailing_list">
                                                <Form.Check
                                                    label="Join our mailing list for the web platform?"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md='4'></Col>
                                    </Row>

                                    <Row>
                                        <Col md='4' />
                                        <Col md='4'>
                                            <div className='d-grid'>
                                                <Button variant='success' disabled={this.state.loading} type='submit'>
                                                    {this.state.loading ?
                                                        <>
                                                            <Spinner animation="border" role="status">
                                                            </Spinner>
                                                            <span className='ml-2'>Submitting...</span>

                                                        </>
                                                        : "Submit Form"}
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col md='4' />
                                    </Row>
                                </Form>
                                {
                                    this.state.showToast ?
                                        <Row className='mt-4'>
                                            <Col md='2' />
                                            <Col md='8'>
                                                <ToastMessage variant={this.state.toastVariant} dismissToast={this.dismissToast}>
                                                    {this.state.toastMessage}
                                                </ToastMessage>
                                            </Col>
                                            <Col md='2' />
                                        </Row>
                                        : null
                                }
                            </Card.Body>
                        </Card>
                    </DataViewLayout>
                </FeedLayout>
            </>
        );
    }
}

export default DemoRequest;