import React, { Component } from 'react';

class PageNotFound extends Component {
    state = {};
    render() {
        return (
            <>
                <div className='row mt-4 mx-0' style={{ paddingTop: '65px', paddingLeft: '90px' }}>
                    <h1>Oops! This page doesn't seem to exist!</h1>
                </div>
            </>);
    }
}

export default PageNotFound;
