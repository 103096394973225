// Necessary React Packages
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// Import the warper component for each page here
import LandingPage from './components/NewLandingPage';
import NavOverview from './components/NavOverview';
import BottomNavOverview from './components/BottomBar';
import PageNotFound from './page404/page404';
import AboutUs from './components/AboutUs';
import Developer from './components/Developer';
import DemoRequest from './components/DemoRequest';


class Routes extends Component {
   
    items = [
        { name: 'home', label: 'Home' },
        { name: 'feed', label: 'Feed' },
        { name: 'algorithms', label: 'Algorithms' },
      ]
      
    render() { 
        return ( 
            //<Provider store={ store }>
                <Router>
                    
                    <NavOverview />
                    <Switch>
                        <Route exact path='/' component={ Developer } />
                        <Route exact path='/overview/demo_request' component={ DemoRequest } />
                        <Route exact path='/overview/about_us' component={ AboutUs } />
                        <Route exact path='/page_not_found' component={ PageNotFound } />
                        <Route component={ PageNotFound } />
                    </Switch>
                </Router>
           // </Provider>
        );
    }
}

export default Routes;
