/*
* Copyright Codex Laboratories LLC 2021.
* Created By: Tyler Fedrizzi
* Modified By:
* Created On: January 8th, 2022
* Modified On: 
*
* Description: Navigation bar for viewing Overview of SWARM system
*/
// React Imports
import React, { Component } from 'react';

// Bootstrap Imports
import { Navbar, Nav, Form, Button, InputGroup, Row, Col, FormControl } from 'react-bootstrap';

import validator from 'validator';


class MailingListForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailError: null
        }
    };

    handleFormSubmission = async (e) => {
        let name = e.target.elements.name.value;
        let email = e.target.elements.email.value;
        console.log(name, email);

        if (name === null || email === null) {
            console.log("Form was empty!");
            return;
        }
        const isValid = this.validateSubmission(name, email);
        console.log(isValid);
        if (isValid) {
            const formData = new FormData();

            formData.append('mailing_list[name]', name);
            formData.append('mailing_list[name]', email);

            const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

            // Post basic information to the simulations db
            const simulation = await fetch('/api/v1/mailing_lists/add_user', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': csrf
                },
                body: formData
            }).then(res => res.json());

            alert("You were added to our list! Thank you!");
        }
    } // handleFormSubmission

    validateSubmission = (name, email) => {
        let isValid = true;
        if (name === "") {
            alert("Name cannot be blank!");
            return false;
        } else if (email === "") {
            alert("Email cannot be blank!");
            return false;
        }

        var email_statement = null;
        if (validator.isEmail(email)) {
            email_statement = 'Valid Email :)';
        } else {
            console.log("Invalid email detected!")
            email_statement = 'Enter valid Email!';
            this.setState({
                emailError: email_statement
            });
            return false;
        }
        this.setState({
            emailError: null
        });
        return isValid;
    }

    render() {
        return (
            <>
            <Form onSubmit={(e) => { e.preventDefault(); this.handleFormSubmission(e); }} name="mailing_list_form" id="mailing_list_form" scope="mailing_list">

                <Row className="align-items-center">
                    <Col sm={"auto"}>
                        <Form.Control
                            name="mailing_list[name]"
                            className="mb-2"
                            type="text"
                            placeholder="Name"
                            id="name"
                        ></Form.Control>
                    </Col>
                    <Col sm="auto">
                        <FormControl type="text" id="email" name="mailing_list[email]" placeholder="Email"></FormControl>
                        <span style={{
                            fontWeight: 'bold',
                            color: 'red',
                        }}>{this.state.emailError}</span>
                    </Col>

                    <Col sm="auto">
                        <Button type="submit" variant="success" block className="mb-2">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
            </>
        )
    }
}

export default MailingListForm;